.imageHolderLogoQuiz{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    border: 1px solid #E4E7EC;
}
.logoImgQuizPage{
    width: 69px;
    height: 24px;
    padding: 24px;
}
.headerHolderDiv{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.tabsDivHolder{
    display: flex;
    align-items: center;
}
.linksPageHeader{
    text-decoration: none;
    color: #101928;
    font-weight: 600;
}

.Homepage_header{
    /* border-bottom: 1px solid #CCCCCC; */
    box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.04);
    padding: 2px 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 500;
}
.Homepage_header .Header_login{
    /* border: 1px solid black;x     */
    padding: 10px;
    font-size: 15px;
    color: black;
    border-radius: 8px;
    margin-right: 30px;
}
.Header_login:hover{
    background-color: #a7a3a3;
    color: white;
}
.Homepage_header .Header_CreateAcc{
    padding: 13px 30px;
    font-size: 15px;
    background-color: black;
    color: white !important;
    border-radius: 5px;
}
.Header_CreateAcc:hover{
    background-color: #4c4e4f;
}
.Homepage_Menudisplay{
    display: none;
}
.Homepage_headerNavbar{
    box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.04);
    position: sticky;
    top: 0;
    /* padding: 2px 6rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Admin_headerNotification{
    font-size: 28px;
    margin-right: 10px;
}
.Admin_wholeNavigationBar{
    display: flex;
}
.Admin_HeaderNotification_div{
    position: relative;
}
.Admin_NotificationSign{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background-color: red;
    top: 3px;
    left: 16px;
}
@media screen and (max-width: 900px){
    .headerHolderDiv{
        padding: 2px 1rem;
        display: none;
    }
    .Homepage_headerNavbar{
        /* padding: 2px 1rem; */
    }
    .Homepage_Menudisplay{
        display: block;
        width: 100%;
        margin: auto;
    }
    .Homepage_ShownMenuBar{
        display: flex;
        flex-direction: column;
    }
    .HomePage_ShownDetails_NavMenu{
        background-color: white;
        /* display: none; */
        transform: translateX(-100%);
        transition: transform ease-out 0.5s;
        position: fixed;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        margin-top: 60px;
    }
    .HomePage_ShownDetails_OpenNavMenu{
        background-color: white;
        z-index: 100;
        transform: translateX(0%);
        transition: transform ease-out 0.5s;
        position: fixed;
        width: 100%;
        display: flex;        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        width: 100%;
        margin-top: 60px;
    }
    .Homepage_MeniIcon{
        color: black;
        font-size: 25px;
    }
    .Header_login{
        /* border: 1px solid black; */
        padding: 10px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        color: black;
        border-radius: 8px;
        margin-right: 30px;
        width: 150px;
        margin: 10px 0;
        /* margin-right: 20px; */
    }
    .HomePage_ShownDetails_NavMenu{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 20px;
    }
    .Header_CreateAcc{
        padding: 10px;
        font-size: 15px;
        background-color: black;
        color: white !important;
        width: 130px;
        display: flex;
        justify-content: center;
        border-radius: 8px;
        margin-bottom: 10px;
        /* margin-right: 20px; */
    }
}